import { ref } from '@vue/composition-api'

import router from '@/router'
import axiosClient from '@/helpers/axios'
import useCountries from '@/services/admin/countryService'

export default function useDepartements() {
  const {
    getCountry, country,
  } = useCountries()
  const departement = ref([])
  const loader = ref(false)
  const departementProcess = ref(false)
  const departementSuccess = ref(false)
  const departements = ref([])
  const errors = ref('')

  // Liste des departements
  const getDepartements = async () => {
    loader.value = true
    await axiosClient.get('/departements').then(response => {
      if (response.data.success === true) {
        loader.value = false
        departements.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir les departements par pays
  const getDepartementByCountry = async () => {
    await getCountry(router.currentRoute.params.slug)
    departements.value = country.value.departements.length > 0 ? country.value.departements : []
  }
  // Obtenir UN departement
  const getDepartement = async slug => {
    const response = await axiosClient.get(`/departements/${slug}`)
    departement.value = response.data.data
  }
  // Ajouter UN departement
  const createDepartement = async data => {
    errors.value = ''
    departementProcess.value = true

    await axiosClient.post('/departements', data)
      .then(response => {
        if (response.data.success === true) {
          console.log(data)
          departementSuccess.value = true
          departementProcess.value = false
          getDepartementByCountry()
        }
      })
      .catch(error => {
        departementProcess.value = false
        departementSuccess.value = false
        errors.value = error.response.data.errors
      })
  }

  // Modifier UN departement
  const updateDepartement = async data => {
    errors.value = ''
    departementProcess.value = true
    await axiosClient.put(`/departements/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          departementSuccess.value = true
          departementProcess.value = false
          getDepartementByCountry()
        }
      })
      .catch(error => {
        departementProcess.value = false
        departementSuccess.value = false

        errors.value = error.response.data.errors
      })
  }

  return {
    errors,
    departement,
    departements,
    getDepartements,
    departementProcess,
    createDepartement,
    updateDepartement,
    getDepartement,
    loader,
    departementSuccess,
    getDepartementByCountry,
    country,
  }
}
