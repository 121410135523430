import { ref } from '@vue/composition-api'

// import router from 'vue-router';
import axiosClient from '@/helpers/axios'

// import router from '../router/index.js';

export default function useCountries() {
  const country = ref([])
  const loader = ref(false)
  const countryProcess = ref(false)
  const countrySuccess = ref(false)
  const countries = ref([])
  const errors = ref('')

  // Liste des countries
  const getCountries = async () => {
    loader.value = true
    await axiosClient.get('/pays').then(response => {
      if (response.data.success === true) {
        loader.value = false
        countries.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir un country
  const getCountry = async slug => {
    const response = await axiosClient.get(`/pays/${slug}`)
    country.value = response.data.data
  }
  // Ajouter un country
  const createCountry = async data => {
    errors.value = ''
    countryProcess.value = true
    await axiosClient.post('/pays', data)
      .then(response => {
        if (response.data.success === true) {
          countrySuccess.value = true
          countryProcess.value = false
          getCountries()
        }
      })
      .catch(error => {
        countryProcess.value = false
        countrySuccess.value = false
        errors.value = error.response.data.errors
      })
  }

  // Modifier un country
  const updateCountry = async data => {
    errors.value = ''
    countryProcess.value = true
    await axiosClient.put(`/pays/${data.slug}`, data)
      .then(response => {
        if (response.data.success === true) {
          countrySuccess.value = true
          countryProcess.value = false
          getCountries()
        }
      })
      .catch(error => {
        countryProcess.value = false
        countrySuccess.value = false

        errors.value = error.response.data.errors
      })
  }

  return {
    errors,
    country,
    countries,
    getCountries,
    countryProcess,
    createCountry,
    updateCountry,
    getCountry,
    loader,
    countrySuccess,
  }
}
